@charset "UTF-8";


@font-face {
	font-family: 'Vodafone Rg';
	src: url('assets/fonts/vit_woff_vfrg_rg_it.woff2');
}

@font-face {
	font-family: 'Vodafone Bd';
	src: url('assets/fonts/vit_woff_vfrg_bd_it.woff2');
}

@font-face {
	font-family: 'Vodafone ExB';
	src: url('assets/fonts/vit_woff_vfexb_rg_it.woff2');
}

@font-face {
	font-family: 'Vodafone Lt';
	src: url('assets/fonts/vit_woff_vflt_rg_it.woff2');
}


/* 
@font-face {
	font-family: 'Vodafone Rg';
	src: url('https://privati.vodafone.it/_next/static/media/VodafoneRg-Regular.1208ef49.woff2');
}

@font-face {
	font-family: 'Vodafone Bd';
	src: url('https://privati.vodafone.it/_next/static/media/VodafoneRg-Bold.368e49c5.woff2');
}

@font-face {
	font-family: 'Vodafone ExB';
	src: url('https://privati.vodafone.it/_next/static/media/VodafoneExB-Regular.7362f3f2.woff2');
}

@font-face {
	font-family: 'Vodafone Lt';
	src: url('https://privati.vodafone.it/_next/static/media/VodafoneLt-Regular.50e90eca.woff2');
} */

/* @font-face {
	font-family: 'Vodafone Rg';
	src: url('https://business.vodafone.it/_next/static/media/VodafoneRg-Regular.1208ef49.woff2');
}

@font-face {
	font-family: 'Vodafone Bd';
	src: url('https://business.vodafone.it/_next/static/media/VodafoneRg-Bold.368e49c5.woff2');
}

@font-face {
	font-family: 'Vodafone ExB';
	src: url('https://business.vodafone.it/_next/static/media/VodafoneExB-Regular.7362f3f2.woff2');
}

@font-face {
	font-family: 'Vodafone Lt';
	src: url('https://business.vodafone.it/_next/static/media/VodafoneLt-Regular.50e90eca.woff2');
} */

* {
	font-family: 'Vodafone Lt';
}

:root {
	--slide: 500px;
	--gap: 33px;
	--pad: 80px;
}

html,
body {
	margin: 0;
	padding: 0;
	overflow-x: hidden;
}


.footer{
	display: flex;
	position: absolute;
	left: 0;
	right: 0;
	height: 200px;
	/* background-color: #0D0D0D;
	color: white; */
}

.header ul {
	background-color: #4a4d4e;
	height: 34px;
	display: block;
	padding: 0 var(--pad);
	margin: 0;
}

.header ul li {
	float: left;
	list-style-type: none;
	display: block;
	margin: 0 10px;
	line-height: 34px;
}

.header ul li.last {
	float: right;
}

.header ul li a {
	color: #ffffff;
	text-decoration: none;
	padding: 0 17px 0 23px;
	font-size: 14px;
	display: block;
	line-height: 34px;
}

.header ul li img {
	margin-right: 11px;
	height: 10px;
}

.header ul li:hover {
	background-color: #ffffff;
}

.header ul li:hover a {
	color: #4a4d4e;
}

.Page {
	margin-bottom: 146px;
}

.LoginPage .header .section,
.RegistrationPage .header .section {
	height: 140px;
	border-radius: 0px 0px 20px 20px;
	background: linear-gradient(90deg, rgba(0, 0, 0, 0.60) 1.73%, rgba(0, 0, 0, 0.00) 61.33%), linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), #E60000;
}



.LoginPage .header .section svg,
.RegistrationPage .header .section svg {

	height: 30px;
	width: 30px;
}

.header .section svg {
	margin: 24px 0 0 84px;
	height: 48px;
	width: 48px;
}

.header .section h2 {
	color: #ffffff;
	margin: 14px 0 0 84px;
	font-family: "Vodafone Lt";
	font-size: 36px;
	font-style: normal;
	font-weight: 400;
	line-height: 38px;
}

.header .section h2 strong {
	font-family: "Vodafone ExB";
	font-size: 36px;
	font-style: normal;
	font-weight: 400;
	line-height: 38px;
}

.tabs-ui {
	margin-top: 30px;
	width: 100%;
}

.link-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	align-self: stretch;
	border-bottom: 2px solid #BEBEBE;
	height: 60px;
}

.link-container ul {
	display: flex;
	align-items: flex-start;
	margin: 0;
	padding: 0;
}

.link-container ul li {
	display: flex;
	padding: 12px 64px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1 0 0;
	position: relative;
	margin: 0;
	cursor: pointer;
}

.link-container ul li a {
	color: #333;
	text-align: center;
	/* Mobile/H3/Light */
	font-family: "Vodafone Lt";
	font-size: 30px;
	font-style: normal;
	font-weight: 400;
	line-height: 38px;
	/* 126.667% */
	text-decoration: none;
}

.link-container ul li.ui-state-active a {
	font-family: "Vodafone ExB";
}

.link-container ul li .tabIndicator {
	width: 100%;
	height: 2px;
	position: absolute;
	right: -0.5px;
	bottom: 0px;
	border-radius: 1px 1px 0px 0px;
	background: var(--new-palette-default-monochrome-monochrome-3, #BEBEBE);
}

.link-container ul li.ui-state-active .tabIndicator {
	background: #E60000;
}

.mainContent {
	display: block;
	margin: 60px auto;
	width: 310px;
}

.EnrollStart .mainContent,
.EnrollSelectNumber .mainContent {

	width: auto;
	padding: 0 var(--pad);
}

.EnrollEnd .mainContent {
	width: auto;
	max-width: 900px;
}


.mainContent .input-field {
	position: relative;
}

.mainContent #inputUsername {
	margin-bottom: 16px;
}

.input-field label {
	padding: 18px 16px 18px 20px;
	pointer-events: none;
	position: absolute;
	left: 0;
	top: 0;
	transition: 0.2s;
	transition-timing-function: ease;
	transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
	opacity: 0.5;
	background: #fff;
	line-height: 20px;
	margin: 1px;
	border-radius: 5px;
	font-size: 18px;
	color: var(--new-palette-default-monochrome-monochrome-4, #7E7E7E);
}

.input-field {
	position: relative
}


.input-field input,
.input-field select {
	display: flex;
	width: 100%;
	padding: 18px 16px 18px 20px;
	border-radius: 5px;
	border: 1px solid var(--new-palette-default-monochrome-monochrome-3, #BEBEBE);
	background: #FFF;
	color: var(--new-palette-default-monochrome-monochrome-5, #262626);
	font-family: "Vodafone Rg";
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	margin: auto;
	outline: none;
	-webkit-text-stroke-color: #666666
}

.input-field select {
	height: 60px;
}

/* .customSelect {
	display: flex;
	width: 406px;
	height: 60px;
	flex-direction: column;
	align-items: flex-start;
	gap: -9px;
}
.customSelect .input-field{
	width: 100%;
} */

.customSelect .input-field input,
#tab_login .input-field input,
.InsertNumber .input-field input {
	width: calc(100% - 38px);
}

.customSelect .input-field label,
.input-field input:focus+label,
.input-field select+label,
.input-field input:valid+label,
.input-field input:not(:empty)+label,
.input-field input:autofill+label,
.input-field input:not(:placeholder-shown)+label {
	opacity: 1;
	transform: scale(1) translateY(-70%) translateX(0px);
	font-size: 13px;
	padding: 0 5px;
	margin: 0 15px;
	color: var(--new-palette-default-monochrome-monochrome-5, #262626);
}


.mainContent .input-field .info-icon {
	position: absolute;
	display: block;
	width: 26px;
	height: 26px;
	z-index: 100;
	top: 18px;
	right: 16px;
	cursor: pointer;
}


.mainContent .listLink {
	margin: 24px 0;
}

.mainContent .listLink a {
	color: var(--new-palette-default-monochrome-monochrome-5, #262626);
	font-family: "Vodafone Rg";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 22px;
	transition: .5s ease;
	text-decoration: underline;
	cursor: pointer;
}

.insert_manually_legend {
	color: var(--New-palette-Default-Monochrome-Monochrome5, #262626);
	text-align: center;
	font-family: "Vodafone Rg";
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: 32px;
	/* 145.455% */
}

.bottomSection a:hover,
.listLink a:hover,
.accordionContent a:hover {
	color: #00697C !important;
}

.mainContent .checkbox {
	margin: 0;
	height: 34px;
	clear: both;
}

.mainContent .checkbox label {
	cursor: pointer;
	display: block;
	margin: 5px 0 5px 8px;
	line-height: 18px;
	float: left;
	color: var(--new-palette-default-monochrome-monochrome-5, #262626);
	/* Mobile/Body/Footnote */
	font-family: Vodafone Rg;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	user-select: none;
}

.mainContent button,
.bottomSection button,
.secondaryContent button {
	display: block;
	width: 100%;
	height: 45px;
	padding: 10px 24px;
	border-radius: 30px;
	background: var(--new-palette-default-primary-primary-1, #E60000);
	border: 0;
	color: #ffffff;
	margin: 18px auto 0;
	color: var(--new-palette-default-monochrome-monochrome-1, #FFF);
	text-align: center;
	font-family: Vodafone Rg;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 22px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	cursor: pointer;
	transition: .5s ease;

}

.lowerBand .actionsButton,
.secondaryContent .actionsButton {
	margin-top: 80px;
	min-height: 45px;
	width: calc(100% - (var(--pad) * 2));
	padding: 20px var(--pad);
	border-top: 1px solid var(--new-palette-default-monochrome-monochrome-3, #BEBEBE);
	border-bottom: 1px solid var(--new-palette-default-monochrome-monochrome-3, #BEBEBE);
	background: rgba(255, 255, 255, 0.85);
	backdrop-filter: blur(3.5px);
}

.secondaryContent .actionsButton button {
	width: 220px;
	float: right;
	margin: 0px auto;
}



.bottomSection .disabled button,
.mainContent .disabled button {
	background: #BBBBBB;
	cursor: default;
}

.secondaryContent .disabled button {
	width: 220px;
	float: right;
	margin: 0px auto;
	background: #BBBBBB;
	cursor: default;
}


.EnrollEnd .mainContent button,
.EnrollStart .mainContent button {
	width: 290px;
}




.accordionSE {
	margin-bottom: 105px !important;
}


.InsertNumber button,
.SelectNumber button {
	width: 220px;
	margin: 32px auto;
}

.InsertNumber .input-field,
.SelectNumber .input-field {
	max-width: 406px;
	margin: auto;

}

.popup.SelectNumber .bottomSection{
	display: flex;
	flex-direction: column;
	align-items: center;
}
.popup.SelectNumber .bottomSection span{
	text-align: center;
	font-family: "Vodafone Rg";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px; /* 137.5% */
}

button:hover {
	background-color: #bd0000;
}

/* start checkbox */
input[type="checkbox"] {
	float: left;
	/* Add if not using autoprefixer */
	-webkit-appearance: none;
	/* Remove most all native input styles */
	appearance: none;
	/* Not removed via appearance */
	margin: 5px auto;

	font: inherit;
	color: currentColor;
	width: 20px;
	height: 20px;
	border: 1px solid #999999;
	border-radius: 3px;
	transform: translateY(-0.075em);

	display: grid;
	place-content: center;
	cursor: pointer;
}

input[type="checkbox"]::before {
	content: "";
	width: 14px;
	height: 11px;
	clip-path: polygon(4% 44%, 0 55%, 44% 100%, 100% 6%, 89% 0%, 40% 80%);
	border-radius: 10px;

	transition: 120ms transform ease-in-out;
	background-color: #ffffff;
	/* Windows High Contrast Mode */
}

input[type="checkbox"]:checked {
	background-color: #00697C;
	border: 1px solid #00697C;
}

input[type="checkbox"]:checked::before {
	transform: scale(1);
}

input[type="checkbox"]:disabled {
	background-color: #CCCCCC;
	border: 1px solid #CCCCCC;
	cursor: not-allowed;
}

input[type="checkbox"]:disabled:before {
	background-color: #CCCCCC;
}

input[type="checkbox"]:disabled:checked:before {
	background-color: darkgrey;
}

/* end checkbox */

input:-internal-autofill-selected {
	background-color: #666666 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

.mainContent h2 {
	color: var(--new-palette-default-primary-primary-4, #25282B);
	text-align: center;

	font-family: Vodafone ExB;
	font-size: 44px;
	font-style: normal;
	font-weight: 400;
	line-height: 52px;
	margin: 16px 0 0;
}

.mainContent h3 {
	color: var(--new-palette-default-monochrome-monochrome-5, #262626);
	text-align: center;
	font-family: "Vodafone Lt";
	font-size: 36px;
	font-style: normal;
	font-weight: 400;
	line-height: 50px;
	margin: 0 0 24px 0;
}




.mainContent h5 {
	color: var(--new-palette-default-monochrome-monochrome-5, #262626);
	text-align: center;
	font-family: "Vodafone Rg";
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: 32px;
	margin: 16px 0 24px;
}

.mainContent .centeredContent {
	width: 406px;
	margin: auto;
	/* display: flex;
	flex-direction: column;
	align-items: center; */
	gap: 24px;
}

.EnrollSelectNumber .mainContent h3 {
	font-family: "Vodafone ExB";
	margin: 0;
}

.EnrollEnd .mainContent h3 {

	margin: 0;
}

.EnrollEnd .mainContent button {

	margin-top: 32px;
}

.EnrollSelectNumber .mainContent {
	margin: 0 auto 64px;
}

.mainContent .userlogo {
	width: 60px;
	height: 60px;
	margin: auto;
}

.loginError {
	color: #E60000;
	margin-top: 30px;
	font-family: 'Vodafone Rg';
}



.secondaryContent h3 {
	color: var(--new-palette-default-monochrome-monochrome-5, #262626);
	text-align: center;
	font-family: "Vodafone ExB";
	font-size: 36px;
	font-style: normal;
	font-weight: 400;
	line-height: 50px;
	margin: 0;
}

.secondaryContent h3 span {
	font-family: "Vodafone ExB";
}

.accordion {
	margin: 20px 0 0 0;
	padding: 0 var(--pad);
}

.accordion .arrow {
	cursor: pointer;
	font-size: 1.3rem;
	display: block;
	right: 0;
	top: 34px;
	bottom: 0;
	transition: .5s;
	transform: rotate(180deg);
	position: absolute;
}

.accordion .arrow.arrowUp {
	transform: rotate(0deg);
}

.accordion .arrow.arrowUp+.accordionContent {
	display: block;
}

.accordion ul {
	margin: 0;
	padding: 0;
}

.accordion ul li span,
.accordion ul li {
	margin: 0;
	padding: 30px 40px 30px 0;
	list-style-type: none;
	min-height: 30px;
	line-height: 30px;
	border-top: 1px solid #BEBEBE;
	color: var(--new-palette-default-monochrome-monochrome-5, #262626);
	font-family: "Vodafone Rg";
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	position: relative;
}

.accordion ul li span {
	border: none;
}

.Mobile * .accordion ul li span {
	font-size: 16px;
	line-height: 20px;
	padding: 16px 0;
}


.secondaryContent .accordion ul li {
	border-top: 1px solid #f2f2f2;
}

.accordion .accordionContent {
	display: none;
	color: var(--new-palette-default-monochrome-monochrome-6, #0D0D0D);
	font-family: Vodafone Rg;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	margin: 30px 0 24px;
}

.accordion .accordionContent a {
	transition: .5s ease;
	color: var(--new-palette-default-monochrome-monochrome-6, #0D0D0D);
}

.background {
	z-index: 10000;
	background: rgba(85, 85, 85, 0.70);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.background .popup {
	border-radius: 20px;
	background: #FFFFFF;
	padding: 0;
	margin: auto;
	height: fit-content;
	width: fit-content;
	max-width: 800px;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	position: absolute;
}

.background .popup .closePopup {

	text-align: right;
	padding: 24px;
}

.background .popup .closePopup svg {
	cursor: pointer;
}

.background .popup .contentPopup {
	padding: 0 106px 64px;
}

.background .popup h3 {
	color: var(--new-palette-default-monochrome-monochrome-5, #262626);
	font-family: "Vodafone ExB";
	font-size: 36px;
	font-style: normal;
	font-weight: 400;
	line-height: 45px;
	margin: 0 0 32px 0;
}

.background .popup p {
	color: var(--new-palette-default-monochrome-monochrome-5, #262626);
	font-family: "Vodafone Rg";
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
}

.background .popup .prompt{
	font-weight: 700;
}

.popup.verificaIdentita .prompt{
	
}

.popup.verificaIdentita .prompt span{
	flex-wrap: wrap;
	word-wrap: break-word;
    word-break: break-all; 
}

.background .popup p strong {
	color: var(--new-palette-default-monochrome-monochrome-5, #262626);
	font-family: "Vodafone Bd";
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
}

.background .popup p strong span {
	color: #E60000;
	font-family: "Vodafone Bd";
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
}

.background .popup .bottomSection p {
	color: #333;
	text-align: center;
	font-family: "Vodafone Rg";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
}

.background .popup .bottomSection a {
	color: var(--new-palette-default-monochrome-monochrome-5, #262626);
	font-family: "Vodafone Bd";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 22px;
	display: block;
	margin: 16px auto 0;
	text-align: center;
	text-decoration: underline;
	cursor: pointer;
	transition: .5s ease;
}

.background .loader {
	display: block;
	margin: auto;
	margin-top: calc(50vh - 50px);
}

.infoMessage {
	height: 56px;
	border-radius: 6px;
	margin: auto;
	margin-bottom: 10pt;
	width: fit-content;
}

.infoMessage span {
	width: 24px;
	height: 34px;
	padding: 11px 14px;
	border-radius: 6px;
	display: block;
	float: left;
}

.infoMessage span svg {
	margin: 5px 0;
}

.infoMessage p {
	margin: 0 10px;
	line-height: 56px;
	float: left;
	display: block;
}

.infoMessage#WARNING {
	border: 1px solid #008A00;
	margin-top: 32px;
}

.infoMessage#WARNING span {
	background-color: #008A00;
}

.infoMessage#ERROR {
	border: 1px solid #EB9700;
}

.infoMessage#ERROR span {
	background-color: #EB9700;
}

.slider {
	margin: 20px auto;
	padding: 0 var(--pad);
	width: fit-content;
	display: none;
}

.slider li {
	display: block;
	float: left;
	list-style-type: none;
	margin: 5px;
	padding: 0;
	width: 6px;
	height: 6px;
	border-radius: 5px;
	border: 1px solid #e60000;
	background-color: #ffffff;
	transition: .5s ease;
	cursor: pointer;
}

.slider li.active {
	background-color: #e60000;
	width: 30px;
}


.boxes {
	margin-top: 24px;
	display: flex;
	width: calc(100% - 160px);
	padding: 0px var(--pad);
	align-items: flex-start;
	gap: 33px;
}




.islider {
	display: flex;
	flex-direction: column;
	width: calc(100% - var(--pad) * 2);
	margin: 24px var(--pad) 0;
	align-items: flex-start;
	gap: 33px;
	scrollbar-width: none;

}

.islider::-webkit-scrollbar {
	display: none;
}

/* .islider * .isliderItem {
	width: calc(33% - 68px);
} */

.islider -webkit-scrollbar {
	display: none;
}


.islider * .isliderItem {
	display: block;
	width: calc(33.33% - var(--pad)*2 - 22px);
	padding: 32px var(--pad);
	flex-direction: column;
	align-items: flex-start;
	flex-shrink: 0;
	align-self: stretch;
	border-radius: 20px;
	border: 1px solid var(--new-palette-default-monochrome-monochrome-3, #BEBEBE);
	background: var(--new-palette-default-primary-primary-2, #FFF);
	float: left;
	margin: 0 10px;
}

.islider * .isliderItem h3 {
	margin: 16px 0 8px 0;
	color: var(--new-palette-default-monochrome-monochrome-5, #262626);
	font-family: "Vodafone ExB";
	font-size: 36px;
	font-style: normal;
	font-weight: 400;
	line-height: 45px;
	text-align: left;
	padding: 0;
}

.islider * .isliderItem h5 {
	margin: 0;
	color: var(--new-palette-default-monochrome-monochrome-5, #262626);
	font-family: "Vodafone Rg";
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 30px;

}




.isliderView {
	display: flex;
	align-items: flex-start;
	width: 100%;
	transition: .5s ease;


}



.otpinput input {
	font-family: "Vodafone ExB";
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px;
	letter-spacing: 18.7px;
	border-style: none;
	outline: none;
	width: 216px;
	margin: auto;
	display: block;
	padding: 0 10px 0px 5px;
	text-indent: 24px;
}

.otpinput input+svg {
	display: block;
	margin: auto;
}

/*Start Mobile*/

.Mobile .header ul {
	display: none;
}

.Mobile .EnrollEnd .mainContent button,
.Mobile .EnrollStart .mainContent button {
	width: fit-content;

}

.Mobile .LoginPage .header .section,
.Mobile .RegistrationPage .header .section {
	border-radius: 0px 0px 6px 6px;
}

.Mobile .header .section h2 strong {
	font-family: "Vodafone RG";
	font-weight: 700;
}

.Mobile .link-container ul li a {
	font-size: 24px;
	font-family: "Vodafone RG";
}

.Mobile .link-container ul li.ui-state-active a {
	font-weight: 700;
}

.Mobile .LoginPage .mainContent .listLink a {
	color: var(--new-palette-default-secondary-secondary-1, #00697C);
	font-weight: 400;
}

.Mobile .LoginPage .checkbox {
	display: none;
}

.Mobile .mainContent button,
.Mobile .bottomSection button,
.Mobile .secondaryContent button {
	border-radius: 6px;
	line-height: 24px;
	font-size: 18px;
	font-weight: 400;
}

.Mobile .accordion ul li {
	border-radius: 6px;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);
	padding: 30px 50px 30px 10px;
	border: 0;
	margin-bottom: 8px;
}

.Mobile .accordion .arrow {
	right: 15px;
}

.Mobile .background .popup {
	border-radius: 6px 6px 0px 0px;
}

.Mobile .background .popup h3 {
	text-align: center;
	font-family: "Vodafone RG";
	font-weight: 700;
	font-size: 24px;
}

.Mobile .background .popup p {
	text-align: center;
	font-size: 16px;
	line-height: 20px;
}

.Mobile .background .popup center p strong,
.Mobile .background .popup center p strong span {
	font-size: 20px;
}

.Mobile .background .popup .bottomSection p {
	font-size: 14px;
	line-height: 18px;
}

.Mobile .background .popup .bottomSection a {
	font-family: "Vodafone Rg";
	font-weight: 400;
	line-height: 20px;
	color: var(--new-palette-default-secondary-secondary-1, #00697C);
}


.lowerBand {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;
}

.buttonPane {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 32px;
}

.buttonPane button {
	display: flex;
	width: 216px;
	height: 45px;
	padding: 10px 24px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	color: blak;
	background-color: white;
	border-radius: 30px;
	color: #262626;
	font-family: "Vodafone Rg";
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 22.105px;
	/* 157.891% */
	letter-spacing: 0.5px;
	text-transform: uppercase;
	border: 0.806px solid var(--New-palette-Default-Monochrome-Monochrome5, #262626);
}


/*End Mobile*/

@media only screen and (max-width: 1240px) {



	/* .islider,
	.boxes {
		height: 300px;
	} */




	/* .boxes .sliderBox {
		width: auto;
		left: var(--pad);
		position: absolute;
		left: 0;
		right: 0;
	} */

	/* .sliderBox.set1 {
		left: var(--pad);
		right: 0;
	}

	.sliderBox.set2 {
		left: calc(50vw - (((var(--slide) + 50px) * 3) + (var(--gap) * 2))/2);
		right: 0;
	}

	.sliderBox.set3 {
		left: calc(100vw - (((var(--slide) + 50px) * 3) + (var(--gap) * 2) + var(--pad)));
	} */
}


@media only screen and (max-width: 1024px) {

	:root {
		--slide: calc(90vw - var(--pad) * 4 - 20px);
	}

	.slider {
		display: block;
	}

	.isliderView {

		width: calc((var(--slide) * 3) + 66px + var(--pad) * 6);

	}

	.islider .isliderItem,
	.boxes .box {
		width: var(--slide);
	}

	.islider {

		overflow-x: scroll;
	}
}


@media only screen and (max-width: 800px) {
	.background .popup p {
		font-size: 17px;
	}
}

@media only screen and (max-width: 750px) {
	:root {
		--pad: 32px;
	}


	.buttonPane {
		flex-direction: column;
		gap: 0;
	}

	.header ul {
		padding: 0 20px;
	}

	.header ul li {
		margin: 0;
	}

	.header .section svg {
		margin: 24px 0 0 32px;
	}

	.header .section h2 {
		margin: 14px 0 0 32px;
	}

	.tabs-ui {
		margin-top: 16px;
	}

	.link-container {
		height: 58px;
	}

	.link-container ul li a {
		font-size: 28px;
		line-height: 36px;
	}

	.mainContent {
		margin: 40px auto;
	}

	.infoMessage p {
		float: revert;
		line-height: 28px;
		font-size: 15px;
		margin: 0 10px 0 60px;
	}


}

@media only screen and (max-width: 600px) {
	:root {
		--gap: 15px;
		--pad: 20px;
	}

	.accordion .arrow {
		top: 20px;
	}


	.header ul {
		height: 24px;
		padding: 0;
	}

	.header ul li,
	.header ul li a {
		line-height: 24px;
	}

	.header .section h2,
	.header .section h2 strong {
		font-size: 35px;
	}

	.tabs-ui {
		margin-top: 14px;
	}

	.mainContent {
		margin: 32px auto;
	}

	.Mobile .mainContent {
		width: calc(100% - (var(--pad) * 2));
	}

	.secondaryContent h3 {
		padding: 0 var(--pad);
	}

	.Mobile .secondaryContent h3 {
		text-align: left;
	}

	.mainContent h2 {
		font-size: 36px;
	}

	.mainContent h3,
	.secondaryContent h3 {
		font-size: 30px;
	}

	.accordion ul li {
		padding: 15px 40px 15px 0;
	}

	.Mobile .accordion ul li {
		padding: 15px 55px 15px 15px;
	}

	.accordion .accordionContent {
		margin: 15px 0;
	}

	.background .popup {
		top: auto;
		height: auto;
		border-radius: 20px 20px 0 0;
	}

	.background .popup .contentPopup {
		padding: 0 45px 50px;
	}
}

@media only screen and (max-width: 500px) {

	.InsertNumber .input-field,
	.SelectNumber .input-field,
	.mainContent .centeredContent {
		width: auto;
	}
}

@media only screen and (max-width: 450px) {
	:root {
		--pad: 16px;
	}

	.secondaryContent .actionsButton button {
		width: 100%;
	}

	.Mobile * .accordion ul li span {
		padding: 16px 0;
	}

	.EnrollEnd .mainContent button,
	.EnrollStart .mainContent button,
	.InsertNumber button,
	.SelectNumber button {
		width: 100%;
	}

	.link-container ul {
		display: block;
		width: 100%;
	}

	.link-container ul li {
		padding: 12px 24px;
		display: block;
		float: left;
		width: calc(50% - 50px);
	}

	.link-container ul li a {
		display: block;
	}

	.EnrollStart .mainContent {
		margin: 0;
	}

	:not(.Mobile) .mainContent h2 {
		font-size: 32px;
	}



	.Mobile * h3 {
		font-family: 'Vodafone Rg' !important;
		font-size: 24px !important;
		line-height: 32px !important;
		font-weight: 700 !important;
	}

	.Mobile .EnrollEnd * h3 {
		font-family: 'Vodafone Rg' !important;
		font-size: 16px !important;
		line-height: 20px !important;
		font-weight: 400 !important;
	}

	.Mobile .EnrollEnd * h3 {
		font-family: 'Vodafone Rg' !important;
		font-size: 16px !important;
		line-height: 20px !important;
		font-weight: 400 !important;
	}

	.Mobile * .verificaIdentita * p {
		font-family: 'Vodafone Rg' !important;
		font-size: 20px !important;
		line-height: 26px !important;
		font-weight: 400 !important;
	}

	/* .Mobile * .verificaIdentita * h3 {
		font-family: 'Vodafone Bd' !important;
		font-size: 32px !important;
		line-height: 40px !important;
		font-weight: 700 !important;
	} */

	:not(.Mobile * h3) .mainContent h3,
	:not(.Mobile) .secondaryContent h3 {
		font-size: 28px;
	}


	.islider * .isliderItem h3,
	.boxes .box h3 {
		font-size: 24px;
		text-align: left;
		padding: 0;
	}

	.islider * .isliderItem h3,
	.boxes .box h5 {
		font-size: 20px;
	}

	.background .popup h3 {
		font-size: 30px;
		line-height: 38px;
		margin: 0 0 16px 0;
	}

	.background .popup .closePopup {
		padding: 12px 24px;
	}

	.accordion ul li {
		font-size: 18px;
	}

	.Mobile .accordion ul li {
		font-size: 16px;
	}


	.Mobile .EnrollEnd .mainContent h2 {
		font-size: 24px;
		line-height: 32px;
	}

	.Mobile .impostazioni {
		display: none;
	}

	.Mobile .EnrollEnd .mainContent h3 {
		font-size: 16px;
		line-height: 20px;
	}

	.Mobile * h5 {
		font-size: 20px !important;
	}


}

@media only screen and (max-width: 380px) {
	.mainContent {
		margin: 32px auto;
		width: calc(100% - (var(--pad) * 2));
	}



	.accordion ul li {
		font-size: 20px;
	}

	.link-container ul li a {
		font-size: 26px;
	}



	.spacer30 {
		min-height: 80px;
	}

}

@media only screen and (max-width: 374px) {

	.popup .infoMessage span {
		padding: 11px 7px;
	}

	.popup .infoMessage#WARNING {
		margin-top: 16px;
	}

	.popup .infoMessage p {
		margin: 0 10px 0 47px;
		font-size: 12px;
	}
}

@media only screen and (max-width: 350px) {
	.header ul li a {
		padding: 0 15px;
	}

	.Mobile .EnrollEnd .mainContent button,
	.Mobile .EnrollStart .mainContent button {
		font-size: 15px;
	}
}

@media only screen and (max-width: 559px) {
	.header ul li.last {
		display: none;
	}
}

@media only screen and (max-height: 600px) {

	.Mobile * .verificaIdentita * p {
		font-size: 16px !important;
		line-height: 20px !important;
	}

	.background .popup .contentPopup {
		padding: 0 45px 20px;
	}
}


.customSelect ul {
	border-width: 1px;
	border-color: #BEBEBE;
	border-style: solid;
	border-top-style: none;
	padding: 18px 10px 10px 20px;
	border-radius: 5px;
	/* width: 100%; */
	padding-top: 20px;
	margin-top: 0;
}


.customSelect input {
	/* width: auto; */
}

.input-field li {
	list-style-type: none;
	/* height: 64px; */
	margin-top: 0px;
	margin-bottom: 20px;
	cursor: pointer;
}


.customSelect input {
	display: flex;
	width: 100%;
	padding: 18px 16px 18px 20px;
	border-radius: 5px;
	border: 1px solid var(--new-palette-default-monochrome-monochrome-3, #BEBEBE);
	background: #FFF;
	color: var(--new-palette-default-monochrome-monochrome-5, #262626);
	font-family: "Vodafone Rg";
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	margin: auto;
}



.customSelect .info-icon {
	position: absolute;
	display: block;
	width: 26px;
	height: 26px;
	z-index: 100;
	top: 18px;
	right: 16px;
	cursor: pointer;
	background: white;
}


.newInfo {
	display: flex;
	flex-direction: row;
	align-content: stretch;
	font-size: 20px;
	border-radius: 6px;
	border-style: solid;
	border-width: 1px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.contentPopup center .newInfo {
	max-width: 528px;
}

.newInfo .errorIcon {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	min-width: 52px;
	min-height: 100%;
}

.newInfo .errorIcon svg {
	min-height: 24px;
	min-width: 24px;
}

.newInfo span {
	font-size: 16px;
	line-height: 22px;
	padding-top: 16px;
	padding-bottom: 16px;
	padding-left: 20px;
	padding-right: 20px;
}

.errorInfo {
	border-color: #EB9700;
}


.errorInfo .errorIcon {
	background-color: #EB9700;
}

.messageInfo {
	border-color: green;
}


.messageInfo .errorIcon {
	background-color: green;
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;

}

input[type="number"] {
	-moz-appearance: textfield;

}