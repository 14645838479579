@charset "UTF-8";

@font-face {
  font-family: 'Vodafone Rg';
  src: url('https://business.vodafone.it/_next/static/media/VodafoneRg-Regular.1208ef49.woff2');
}

@font-face {
  font-family: 'Vodafone Bd';
  src: url('https://business.vodafone.it/_next/static/media/VodafoneRg-Bold.368e49c5.woff2');
}

@font-face {
  font-family: 'Vodafone ExB';
  src: url('https://business.vodafone.it/_next/static/media/VodafoneExB-Regular.7362f3f2.woff2');
}

@font-face {
  font-family: 'Vodafone Lt';
  src: url('https://business.vodafone.it/_next/static/media/VodafoneLt-Regular.50e90eca.woff2');
}


.vert-box {
  display: flex;
  flex-direction: column;

  margin: 20px;
  padding: 20px;
  border-style: none;
  border-color: rgba(0, 0, 0, 0.4);
  border-width: 1px;


}

.hor-box {
  display: flex;
  flex-direction: row;
  margin: 20px;
  padding: 20px;
  border-style: none;
  border-color: rgba(0, 0, 0, 0.2);
  border-width: 1px;
}



.title {
  font-size: 20pt;
  font-family: 'Vodafone ExB';
  margin-bottom: 20px;
}

.bigline {
  margin-top: 20pt;
  font-size: 20px;
  margin-bottom: 20pt;

}



.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-family: "Vodafone Rg";
}


/* .biginput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  

  overflow: hidden;
}

.biginput input {
  color: var(--new-palette-default-monochrome-monochrome-5, #262626);
  text-align: center;


  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  width: 24px;
  border-style: none;
  border-bottom: 1px solid #262626;
  outline: none;
  text-align: center;

  margin: 0 5px;
  font-family: "Vodafone ExB";


} */

.biginput {
  width: 228px;
  margin: auto;
}


.bigbutton {
  background-color: #E60000;
  color: white;
  font-size: 14px;
  border-radius: 30px;
  border-style: none;
  padding: 20px;
}


.waiting {
  display: absolute;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}



.Page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.biginput {}



.biginput input {
  margin-bottom: -18px;
  font-family: "Vodafone ExB";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 19px;
  border-style: none;
  outline: none;
  padding-left: 6px;
  width: 280px;

}